window.console || (window.console = {
	log: function(){}
});
var SANGETSU = window.SANGETSU || null;

(function($){
	let siteName = 'sangetsu';
	let cssua = window.cssua || null;
	const a = 'is-active';
	const v = 'is-visible';
	const slideSpeed = 'fast';
	const params = new URL(document.location).searchParams;

	SANGETSU = {
		va: {
			pathname: location.pathname,
			hash: location.hash,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			},
			favorite_num: 300
		},
		localDecision() {
			var local = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|test\\.${siteName}\\.com|test\\.${siteName}\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return local.test(location.hostname);
		},
		localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll(`[class*="${key}"]`);
			var includeClassLen = includeClass.length;

			for(i=0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for(i=0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i]+'.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done((...args) => {
					var regExp = new RegExp(key);

					for(i=0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for(j=0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.'+position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		nextToggle() {
			$('body').on('click', '.js-toggle', function(ev){
				var $this = $(this);
				var $block = $this.parent('.mod-backnumber').find('.js-toggleBlock');

				if ($block.length > 0) {
					$('.js-toggle').toggleClass(v);
					$block.toggleClass(v);
				}
				ev.stopPropagation();
			});
			$('html').on('click', function(ev){
				var isTarget = $(ev.target).closest('.js-toggleBlock').length;
				var isClose = $(ev.target).closest('.no-close').length;

				if (isClose === 0 && isTarget === 0 && $('.js-toggle').hasClass(v)) {
					$('.js-toggle').removeClass(v);
					$('.js-toggleBlock').removeClass(v);
				}
			});
		},
		nextSlideToggle() {
			var $switch = $('.js-slide-toggle');

			$switch.on('click', function(){
				$(this).toggleClass(a);
				$(this).next('.js-slide-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		hoverText(){
			$('.js-hover').each((idx, ele) => {
				if (cssua.ua.desktop) {
					$(ele).mouseenter(() => {
						$(ele).next('.js-hoverText').addClass(v);
					});
					$(ele).mouseleave(() => {
						$(ele).next('.js-hoverText').removeClass(v);
					});
				} else {
					$(ele).on('click', () => {
						let $text = $(ele).next('.js-hoverText');
						if (!$text.hasClass(v)) {
							$('.js-hoverText').removeClass(v);
							$text.addClass(v);
						} else {
							$text.removeClass(v);
						}
					});
				}
			});
		},
		modalView(){
			var $modal = $('.mod-modal'),
				$overlay = $('.mod-modal-overlay'),
				$open = $('.mod-modal-open');

			$open.on('click', function(e){
				e.preventDefault();
				modalResize();
				$overlay.addClass(v).fadeIn(slideSpeed);
				$modal.fadeIn(slideSpeed);
			});

			$overlay.on('click', function(){
				$modal.fadeOut(slideSpeed);
				$overlay.fadeOut(slideSpeed);
			});

			function modalResize(){
				var w = $(window).width();
				var h = $(window).height();
				var cw = $modal.outerWidth();

				if (SANGETSU.va.window.width <= 400) {
					$modal.css({
						'height': (h - 200) + 'px',
						'left': ((w - cw)/2) + 'px'
					});
				} else {
					$modal.css({
						'height': (h - 100) + 'px',
						'left': ((w - cw)/2) + 'px'
					});
				}
			}
		},
		fadeInContent() {
			$('.js-fadeIn').addClass(v);
		},
		getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		switchBiz() {
			var storage;
			storage = localStorage.getItem('watchBiz');
			if (storage === 'biz') {
				$('body').addClass('st_biz');
			} else {
				$('body').addClass('st_cus');
			}
			$('.js-switch-biz, .js-switch-biz-menu').on('click', function(){
				localStorage.setItem('watchBiz', 'biz');
			});
			$('.js-switch-cus').on('click', function(){
				localStorage.setItem('watchBiz', 'cus');
			});
		},
		toggleGlobalNav() {
			// [PC]検索ボックス開閉
			var $inner = $('.nav-menu-list-search-inner');
			var timer, timerSP;

			$('.js-header-search-open').on('click', function(){
				clearTimeout(timer);
				if ($inner.hasClass(v)) {
					$inner.removeClass(a);
					timer = setTimeout(function(){ $inner.removeClass(v); }, '200');
				} else {
					$inner.addClass(v);
					timer = setTimeout(function(){ $inner.addClass(a); }, '100');
				}
			});

			// [Tablet,SP]ハンバーガーメニュー開閉
			$('.js-sp-nav').on('click', function(){
				clearTimeout(timerSP);
				if ($(this).hasClass(a)) {
					$(this).removeClass(a);
					$('.spnav').fadeToggle();
					timerSP = setTimeout(function(){ $('.spheader').removeClass(a); }, '300');
				} else {
					$(this).addClass(a);
					$('.spheader').addClass(a);
					timerSP = $('.spnav').fadeToggle();
				}
			});

			// [PC] グローバルナビ詳細開閉（hover）
			var $switch = $('.js-nav-switch');
			var $switchCorp = $('.js-nav-corp-switch');
			$switch.mouseenter(function(){
				if (!$(this).hasClass(a)) {
					closeGnav();
					setGnavBoxHeight($(this), document.getElementsByClassName('nav-menu-inner'));
					openGnav(this);
				}
			});
			$('.nav.inc_common_nav').mouseleave(function(){
				closeGnav();
			});
			$('.nav-menu-list-head_global, .nav-menu-list-head_qa').mouseenter(function(){
				closeGnav();
			});

			// [Tablet/SP] グローバルナビ詳細開閉（アコーディオン）
			$('.js-spnav-switch').on('click', function(){
				$(this).find('.spnav-menu-switch').toggleClass(a);
				$(this).next('.js-menu').stop().slideToggle(slideSpeed);
			});

			function openGnav(_switch){
				var index = $switch.index(_switch);
				$(_switch).addClass(a);
				$(_switch).parent('.nav-menu-list').addClass(a);
				$('.nav-menu-inner').addClass(a);
				$('.nav-menu-inner-box').eq(index).stop().show();
				setTimeout(function(){ $('.nav-menu-inner-box').eq(index).addClass(a); }, '300');
			}
			function closeGnav(){
				$switch.removeClass(a);
				$switchCorp.removeClass(a);
				$('.nav-menu-list').removeClass(a);
				$('.nav-menu-inner').removeClass(a);
				$('.nav-menu-inner-box').removeClass(a);
				$('.nav-menu-inner-box').stop().hide();
				$('.nav-menu-list-corp-inner').removeClass(a);
				$('.nav-menu-list-corp-box').removeClass(a);
				$('.nav-menu-list-corp-box').stop().hide();
				document.getElementsByClassName('nav-menu-inner')[0].style.height = '0';
			}
			function setGnavBoxHeight(_$this, _inner){
				if (_$this.hasClass('js-nav_style')) {
					_inner[0].style.height = '365px';
				} else if (_$this.hasClass('js-nav_products')) {
					_inner[0].style.height = '400px';
				} else if (_$this.hasClass('js-nav_sscp')) {
					_inner[0].style.height = '340px';
				}	else if (_$this.hasClass('js-nav_book')) {
					_inner[0].style.height = '180px';
				} else if (_$this.hasClass('js-nav_showroom')) {
					_inner[0].style.height = '340px';
				} else if (_$this.hasClass('js-nav_business')) {
					_inner[0].style.height = '300px';
				} else if (_$this.hasClass('js-nav_company')) {
					_inner[0].style.height = '210px';
				} else if (_$this.hasClass('js-nav_ir')) {
					_inner[0].style.height = '210px';
				} else if (_$this.hasClass('js-nav_csr')) {
					_inner[0].style.height = '210px';
				} else if (_$this.hasClass('js-nav_recruit')) {
					_inner[0].style.height = '140px';
				}
			}
		},
		toggleSideNav() {
			$('.mod-sideNav-list-a').each(function(){
				if ($(this).attr('href') === SANGETSU.va.pathname) {
					$(this).addClass(a);
				}
			});

			// 事業所一覧配下
			$('.mod-sideNav-list-inner').each(function(){
				$(this).find('.mod-sideNav-list-a').each(function(){
					var href = $(this).attr('href');
					var area = href.substring(href.indexOf('#')+1, href.length);

					if (SANGETSU.va.pathname === '/company/office/access_' + area + '.html') {
						$(this).addClass(a);
						$(this).parents('.mod-sideNav-list').find('.mod-sideNav-list-item').addClass(a);
					}
				});
				var $thisParents = $(this).parent('.mod-sideNav-list');
				if ($thisParents.find('.mod-sideNav-list-a').hasClass(a)) {
					$(this).addClass(v);
					$(this).parents('.mod-sideNav-list').find('.mod-sideNav-list-item').addClass(a);
				}
			});
		},
		easeScroll() {
			$('a[rel="scroll"]').on('click', (event) => {
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href);
				}
				return false;
			});
			$('map area').on('click', (event) => {
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href);
				}
				return false;
			});

			function scrollToObject(_self){
				let hash = '#' + _self.split('#')[1];
				let pos = hash === '#pagetop' ? 0 : $(hash).offset().top;
				if (hash !== '#pagetop') {
					if (SANGETSU.va.window.width < SANGETSU.va.device.sp) {
						pos -= 60;
					} else if (SANGETSU.va.window.width > SANGETSU.va.device.desktop) {
						pos -= 141;
					} else {
						pos -= 68;
					}
				}
				$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
			}
		},
		easeScrollFixHeader(_height, _heightTAB, _heightSP) {
			$('a[rel="scroll"]').on('click', (event) => {
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href);
				}
				return false;
			});
			function scrollToObject(_self){
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (SANGETSU.va.window.width < SANGETSU.va.device.sp) {
						pos -= _heightSP;
					} else if (SANGETSU.va.window.width > SANGETSU.va.device.desktop) {
						pos -= _height;
					} else {
						pos -= _heightTAB;
					}
				}
				$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
			}
		},
		pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function(){
				if ($(window).scrollTop() > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		yearLinkGeneration() {
			var $obj = $('.js-yearLinkGeneration');
			var $currentY = $('#js-currentYear');
			var $startY = $('#js-startYear');
			// var en = $.THERMOS.common.isEnglish;

			if ($obj.length > 0 && $currentY.length > 0) {
				var dataDir = $obj.attr('data-dir');
				var currentY = Number($currentY.text());
				var path = location.pathname;
				var addText = '年';
				var start = $startY.text() || $obj.attr('data-start');
				var reduceYear = start ? (currentY - Number(start)) : 5;
				var i, dir, html = '';
				var sliceNum = -9;
				reduceYear = reduceYear > 5 ? 5 : reduceYear;

				if (dataDir) {
					dataDir = dataDir === 'infomation' ? dataDir : 'infomation/'+dataDir;
					// dir = en ? '/english/'+dataDir+'/' : '/'+dataDir+'/';
				} else {
					dir = (path.match(/\.html/) || []).length > 0 ? path.slice(0, sliceNum) : path;
				}

				for(i=currentY; i >= currentY - reduceYear; i--) {
					html += '<li class="js-currentList" data-currentList="'+i+'"><a href="'+dir+i+'.html">'+i+addText+'</a></li>';
				}
				$obj.append(html);

				var currentPath = SANGETSU.va.pathname.split('/');
				var currentPathSplit = currentPath[currentPath.length-1];
				var removePathSplit = currentPathSplit.split('.');
				var removePath = removePathSplit[0].replace(/[^0-9]/g, '');

				$('.js-currentList').each(function(){
					if ($(this).attr('data-currentList') === removePath) {
						$(this).remove();
					}
				});
			}
		},
		SearchFormToggle() {
			var $switch = $('.mod-searchForm-hide');

			$switch.on('click', function(){
				$('.mod-searchForm-hide-switch').toggleClass(a);
				$('.mod-searchForm-detail').stop().slideToggle(slideSpeed);
			});
		},
		prodSearchPn() {
			// グロナビ
			var $formPnNav = $('#searchForm-pn-nav');
			var $inputPnNav = $('#product_number-nav');
			$('.js-nav-pn-submit').on('click', function(){
				searchPnGnav($inputPnNav, $formPnNav);
			});
			$inputPnNav.on('keydown', (e) => {
				if (e.key === 'Enter') {
					searchPnGnav($inputPnNav, $formPnNav);
					return false;
				}
			});
			checkStorage_scdv1($formPnNav);

			// SPハンバーガーナビ
			var $formPnSPNav = $('#searchForm-pn-spnav');
			var $inputPnSPNav = $('#product_number-spnav');
			$('.js-spnav-pn-submit').on('click', function(){
				searchPnGnav($inputPnSPNav, $formPnSPNav);
			});
			$inputPnSPNav.on('keydown', (e) => {
				if (e.key === 'Enter') {
					searchPnGnav($inputPnSPNav, $formPnSPNav);
					return false;
				}
			});
			checkStorage_scdv1($formPnSPNav);

			// 画像ダウンロードページ
			var $formPnDl = $('#searchForm-pn-download');
			var $inputPnDl = $('#product_number-download');
			$('.js-download-pn-submit').on('click', function(){
				searchPnGnav($inputPnDl, $formPnDl);
			});
			$inputPnDl.keypress(function(e){
				if (e.which === 13) {
					searchPnGnav($inputPnDl, $formPnDl);
					return false;
				}
			});

			function searchPnGnav(_$input, _$form){
				var inputPn = _$input.val();
				if (!inputPn) {
					alert('品番を入力してください');
				} else {
					inputPn = inputPn.replace(/－/g, '').replace(/-/g, '').replace(/　/g, '')
						.replace(/ +/g, '').replace(/^\s+|\s+$/g, '');
					var inputPnHalf = inputPn.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(tmpStr){
						return String.fromCharCode(tmpStr.charCodeAt(0) - 0xFEE0);
					});
					_$form.attr('action', '/product/list/part/' + inputPnHalf);
					_$form.submit();
				}
			}
			// Casa di Vosne localStorageチェック → 存在しない場合、検索時にパラメータを付加
			function checkStorage_scdv1(_$form){
				if (!localStorage.getItem('scdv1')) {
					_$form.append('<input type="hidden" name="casa" value="M3MA18">');
				} else {
					var scdv1Date = new Date(localStorage.getItem('scdv1'));
					var thisDate = new Date();
					if (Math.ceil((thisDate - scdv1Date) / 86400000) > 90) {
						_$form.append('<input type="hidden" name="casa" value="M3MA18">');
					}
				}
			}
		},
		setFavoCount(_favoCnt) {
			let favoriteStorage = [];
			for (let i in localStorage) {
				if (i.indexOf('favoriteList-') !== -1) {
					favoriteStorage.push(i);
				}
			}
			$('.js-favoCnt').text(favoriteStorage.length);
		},
		downloadCarouselItems() {
			if (cssua.ua.desktop) {
				$('.js-download-carousel').owlCarousel({
					items: 5,
					slideSpeed: 300,
					paginationSpeed: 400,
					pager: false,
					pagination: false,
					nav: true,
					navText: ['', '']
				});
			} else {
				$('.js-download-carousel').owlCarousel({
					items: 2,
					slideSpeed: 300,
					paginationSpeed: 400,
					pager: false,
					pagination: false,
					nav: true,
					navText: ['', '']
				});
			}
		},
		tabSwitch() {
			var $tab = $('.js-tab');
			var $content = $('.js-tab-content');

			$tab.on('click', function(){
				$tab.each(function(){
					$(this).removeClass(a);
				});
				$(this).addClass(a);

				$content.each(function(){
					$(this).removeClass(a).removeClass(v);
				});

				var $switch = $('#'+$(this).attr('data-switch'));
				$switch.addClass(a);
				setTimeout(function(){
					$switch.addClass(v);
				}, '200');
			});
		},
		disableSaveImage() {
			if (SANGETSU.va.pathname.indexOf('/product/detail/') === -1) {
				$('img, video').on('contextmenu', function () {
					return false;
				});
				$('.product img, .favorite img, .case img, .report img, .findimages img, .mod-modal img, video').each(function(){
					$(this).attr('unselectable', 'on')
						.attr('oncontextmenu', 'return false;')
						.attr('onmousedown', 'return false;')
						.attr('onmousemove', 'return false;');
					$(this).draggable = false;
					$(this).on('dragover', function(event){
						event.preventDefault();
						return false;
					}).on('drop', function(event){
						event.preventDefault();
						return false;
					});
				});
				$('.findimages-detail-img img').each(function(){
					$(this).attr('oncontextmenu', 'true;');
				});
			}
		},
		styleNavHint() {
			if (location.href.indexOf('style') > -1) {
				$('.style-hint-nav-link').each(function(){
					var navHint = $(this).attr('data-hint');
					if (location.href.indexOf(navHint) > -1) {
						$(this).addClass(v);
					}
				});
				$('.style-hint-section').each(function(){
					var navHint = $(this).attr('data-hint');
					if (location.href.indexOf(navHint) > -1) {
						$(this).addClass(v);
					}
				});
				$('.style-hint-section-link').each(function(){
					if ($(this).attr('href') === SANGETSU.va.pathname) {
						$(this).addClass(a);
					}
				});
			}
		},
		styleSlideToggle() {
			var $switch = $('.style-hint-archive-cat');
			var $mobile = cssua.ua.mobile;

			if (cssua.ua.mobile && $mobile.indexOf('ipad') !== 0) {
				$switch.on('click', function(){
					$(this).toggleClass(a);
					$(this).next('.style-hint-archive-block').stop().slideToggle(slideSpeed);
				});
			}
		},
		matchHeight() {
			$('.js-matchHeight').each(function(){
				$(this).children().matchHeight();
			});
			$('.js-matchHeight-item').matchHeight();
		},
		accordion() {
			const DURATION = 250;
			const details = document.querySelectorAll('.js-accordion');

			const openAcd = (_ele) => {
				_ele.setAttribute('open', 'true');
				_ele.querySelector('.__wrapper').animate(
					{
						opacity: [0, 1],
						height: [0, _ele.querySelector('.__wrapper').offsetHeight + 'px'],
					},
					{
						duration: DURATION,
						easing: 'ease',
					}
				);
				_ele.querySelector('.__plus').animate(
					{ rotate: ['0deg', '90deg'] },
					{
						duration: DURATION,
						pseudoElement: "::after",
						easing: 'ease',
						fill: 'forwards',
					}
				);
			};
			details.forEach(ele => {
				const summary = ele.querySelector('summary');
				const wrapper = ele.querySelector('.__wrapper');
				const plus = ele.querySelector('.__plus');
				summary.addEventListener('click', e => {
					e.preventDefault();
					if (ele.open) {
						const close = wrapper.animate(
							{
								opacity: [1, 0],
								height: [wrapper.offsetHeight + 'px', 0],
							},
							{
								duration: DURATION,
								easing: 'ease',
							}
						);
						plus.animate(
							{ rotate: ['90deg', '0deg'] },
							{
								duration: DURATION,
								pseudoElement: "::after",
								easing: 'ease',
								fill: 'forwards',
							}
						);
						close.onfinish = () => {
							ele.removeAttribute('open');
						}
					} else {
						openAcd(ele);
					}
				});
			});

			if (params.get('open')) {
				let targetAcd = params.get('open');
				openAcd(document.querySelector(`#${targetAcd}.js-accordion`));

				let pos = $(`#${targetAcd}`).offset().top;
				if (SANGETSU.va.window.width < SANGETSU.va.device.sp) {
					pos -= 60;
				} else if (SANGETSU.va.window.width > SANGETSU.va.device.desktop) {
					pos -= 141;
				} else {
					pos -= 68;
				}
				window.scroll(0, pos);
			}
		},
		modaalYoutube() {
			let $movieList = $('.js-modaalYoutube');
			let target = params.get('movie');
			let $opentgt, openflag;

			const scrollMoviePos = () => {
				if (openflag && $opentgt.closest('.js-accordion').length === 0) {
					let pos = $opentgt.offset().top;
					if (SANGETSU.va.window.width < SANGETSU.va.device.sp) {
						pos -= 60;
					} else if (SANGETSU.va.window.width > SANGETSU.va.device.desktop) {
						pos -= 141;
					} else {
						pos -= 68;
					}
					console.log(pos);
					$('html, body').scrollTop(pos);
				}
			}
			$movieList.modaal({
				type: 'video',
				after_close: () => {
					scrollMoviePos();
					openflag = false;
				}
			});

			if (target && target.match(/^[a-zA-Z0-9\-_]{11}$/)) {
				openflag = true;
				$opentgt = $movieList.filter('[href="https://www.youtube.com/embed/' + target + '"]');
				$opentgt.modaal('open');
			}
		},

		// ----------- ▼▼ pickup//newproduct function ▼▼ -----------
		flotexMenu() {
			var $lineupBtn = $('.flotexMenu-ul-lineup');
			$lineupBtn.on('click', function(){
				if ($lineupBtn.hasClass(a)) {
					$(this).removeClass(a);
				} else {
					$(this).addClass(a);
				}
			});
			$('.lineupMenu-li-a').on('click', function(){
				$lineupBtn.removeClass(a);
			});
			window.onload = function(){
				flotexFix();
			};

			$(window).resize(function(){
				flotexFix();
			});

			function flotexFix(){
				var winW = $(window).width();
				$('.lineupMenu').css('width', winW + 'px'); // プルダウンメニューの横幅指定

				var $flotexMenu = $('.flotexMenu');
				var topOffset = $('.flotexMenu').offset().top;

				$(window).scroll(function(){
					var scrollY = $(window).scrollTop();
					var menuPos = topOffset - scrollY;
					if (winW < 768) {
						if (menuPos < 60) {
							$flotexMenu.addClass('is-fixed');
						} else {
							$flotexMenu.removeClass('is-fixed');
						}
					} else if (winW > 1024) {
						if (menuPos < 80) {
							$flotexMenu.addClass('is-fixed');
						} else {
							$flotexMenu.removeClass('is-fixed');
						}
					} else {
						if (menuPos < 68) {
							$flotexMenu.addClass('is-fixed');
						} else {
							$flotexMenu.removeClass('is-fixed');
						}
					}
				});
			}
		},
		shitsurahi21Menu() {
			window.onload = function () {
				shitsurahi21Fix();
			};

			$(window).resize(function () {
				shitsurahi21Fix();
			});

			function shitsurahi21Fix() {
				var winW = $(window).width();
				var $shitsurahi21Menu = $('.shitsurahi21-nav');
				var topOffset = $('.shitsurahi21-nav').offset().top;

				if (winW > 1024) {
					$(window).scroll(function () {
						var scrollY = $(window).scrollTop();
						var menuPos = topOffset - scrollY;
						if (menuPos < 80) {
							$shitsurahi21Menu.addClass('is-fixed');
						} else {
							$shitsurahi21Menu.removeClass('is-fixed');
						}
					});
				} else {
					$shitsurahi21Menu.removeClass('is-fixed');
				}
			}
			// <tablet/sp>メニュー開閉
			$('.shitsurahi21-menuBtn').on('click', (e) => {
				$(e.currentTarget).toggleClass(a);
				$('.shitsurahi21-nav-inner').toggleClass(v);
			});
		},
		cleasNav() {
			if ($('.inc_newproduct_cleas20_function_nav').length) {
				$('.cleas20-function-nav-a').each((idx, ele) => {
					if ($(ele).attr('href').indexOf(SANGETSU.va.pathname) !== -1) {
						$(ele).addClass(a);
					}
				});
			}
		},
		cleasMenu() {
			window.onload = function () {
				cleas20Fix();
			};

			$(window).resize(function () {
				cleas20Fix();
			});

			function cleas20Fix() {
				var winW = $(window).width();
				var $cleas20Menu = $('.cleas20-nav');
				var topOffset = $('.cleas20-nav').offset().top;

				if (winW > 1024) {
					$(window).scroll(function () {
						var scrollY = $(window).scrollTop();
						var menuPos = topOffset - scrollY;
						if (menuPos < 80) {
							$cleas20Menu.addClass('is-fixed');
						} else {
							$cleas20Menu.removeClass('is-fixed');
						}
					});
				} else {
					$cleas20Menu.removeClass('is-fixed');
				}
			}
			// <tablet/sp>メニュー開閉
			$('.cleas20-menuBtn').on('click', (e) => {
				$(e.currentTarget).toggleClass(a);
				$('.cleas20-nav-inner').toggleClass(v);
			});
		},
		wallcoveringsMenu() {
			if($('.wallcoverings-sp-nav').length) {
				window.onload = function () {
					wallcoveringsFix();
				};

				$(window).resize(function () {
					wallcoveringsFix();
				});

				function wallcoveringsFix() {
					var winW = $(window).width();
					var $wallcoveringsMenu = $('.wallcoverings-sp-nav');
					var topOffset = $('.wallcoverings-sp-nav').offset().top;

					if (winW > 1024) {
						$(window).scroll(function () {
							var scrollY = $(window).scrollTop();
							var menuPos = topOffset - scrollY;
							if (menuPos < 80) {
								$wallcoveringsMenu.addClass('is-fixed');
							} else {
								$wallcoveringsMenu.removeClass('is-fixed');
							}
						});
					} else {
						$wallcoveringsMenu.removeClass('is-fixed');
					}
				}
				// <tablet/sp>メニュー開閉
				$('.wallcoverings-menuBtn').on('click', (e) => {
					$(e.currentTarget).toggleClass(a);
					$('.wallcoverings-sp-nav-inner').toggleClass(v);
				});
			}
		},
		// ----------- ▲▲ pickup//newproduct function ▲▲ -----------

		loadDelayScript() {
			var _this = this;
			_this.pageTop();
			_this.nextToggle();
			_this.nextSlideToggle();
			_this.hoverText();
			_this.modalView();
			_this.fadeInContent();
			_this.switchBiz();
			_this.toggleGlobalNav();
			_this.toggleSideNav();
			_this.yearLinkGeneration();
			_this.prodSearchPn();
			_this.setFavoCount();
			_this.downloadCarouselItems();
			_this.tabSwitch();
			_this.disableSaveImage();
			_this.styleNavHint();
			_this.styleSlideToggle();
			_this.matchHeight();
			_this.accordion();
			_this.modaalYoutube();

			if (SANGETSU.va.pathname.indexOf('/case/') === -1 &&
				SANGETSU.va.pathname.indexOf('/findimages/') === -1 &&
				SANGETSU.va.pathname.indexOf('/findimages_show/') === -1) {
				_this.SearchFormToggle();
			}

			if (SANGETSU.va.pathname.indexOf('/pickup/flotex/') !== -1) {
				_this.flotexMenu();
			}
			if (SANGETSU.va.pathname.indexOf('/newproduct/cleas/') !== -1) {
				_this.cleasNav();
				_this.cleasMenu();
			}
			if (SANGETSU.va.pathname.indexOf('/pickup/shitsurahi21/') !== -1) {
				_this.shitsurahi21Menu();
			}
			if (SANGETSU.va.pathname.indexOf('/newproduct/wallcoverings/') !== -1) {
				_this.wallcoveringsMenu();
			}

			// easeScroll個別設定 ※LP用ヘッダーの場合(80, 68, 60)
			if (SANGETSU.va.pathname.indexOf('/newproduct/aquaclean/') !== -1) {
				_this.easeScrollFixHeader(130, 68, 60);
			} else if (SANGETSU.va.pathname.indexOf('/pickup/attractive_moment/') !== -1) {
				_this.easeScrollFixHeader(80, 68, 60);
			} else if (SANGETSU.va.pathname.indexOf('/pickup/finlayson/') !== -1) {
				_this.easeScrollFixHeader(80, 68, 60);
			} else if (SANGETSU.va.pathname.indexOf('/newproduct/xselect21/') !== -1) {
				_this.easeScrollFixHeader(80, 68, 60);
			} else if (SANGETSU.va.pathname.indexOf('/newproduct/strings21/') !== -1) {
				_this.easeScrollFixHeader(80, 68, 60);
			} else if (SANGETSU.va.pathname.indexOf('/pickup/scion/') !== -1) {
				_this.easeScrollFixHeader(80, 68, 60);
			} else if (SANGETSU.va.pathname.indexOf('/pickup/moomin/') !== -1) {
				_this.easeScrollFixHeader(80, 68, 60);
			} else {
				_this.easeScroll();
			}
		}
	};

	$(() => SANGETSU.localDecision() ? SANGETSU.localLoading() : SANGETSU.loadDelayScript());
})(window.jQuery);

$(window).on('load', () => {
	const hash = location.hash;
	if (hash) {
		if ($('.js-easeScrollHash').length) {
			$('html, body').css('display', 'none');
			setTimeout(() => {
				$('html, body').css('display', 'block');
				let _hash = '#' + hash.split('#')[1];
				let pos = $(_hash).offset().top;
				$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
			}, 100);
		}
	}
});
